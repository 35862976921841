export default {
  "dashboard.recentTransaction": "Recent transactions",
  "dashboard.statistic": "Statistics",
  "dashboard.card": "Last payment",
  "dashboard.graph": "Account status",
  "dashboard.filter.period": "Period",
  "dashboard.filter.name": "Name",
  "dashboard.filter.type": "Type",
  "dashoard.statistic.title": "Expenses of all members",
  "dashoard.statistic.details": "Details",
  "dashboard.statistic.filter.title": "Filter",
  "dashboard.statistic.filter.spending": "Spending",
  "dashboard.statistic.filter.income": "Income",
  "dashboard.error.getMember": "Error while getting members list.",
  "dashboard.error.getCategory": "Error while getting categories list.",
  "dashboard.error.getMemberStatistic": `Error while getting member's statistic.`,
  "dashboard.error.noTransactionYet": `No transaction yet.`,
  "dashboard.label.memberExpenses": "Expenses all members",
  "dashboard.label.categoriesExpensesRecipes":
    "Expenses and recipes all categories",
  "dashboard.label.fluxRecipes": "Recipes all Flux",
  "dashboard.label.fluxExpenses": "Expenses all Flux",
  "dashboard.error.noStat": "No data to calculate yet for theses criteria.",
  "dashboard.error.getTransactionCard":
    "Error while getting latest transactions by card.",
  "dashboard.text.noTransactionCardYet": `No card payment yet.`,
  "dashboard.balance": `Balance`,
  "dashboard.limits": "Limits",
  "dashboard.daily": "Daily",
  "dashboard.monthly": "Monthly",
};
