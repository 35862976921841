import gql from "graphql-tag";

export const CARD_FRAGMENT = gql`
  fragment CardInfo on Card {
    id
    deliveryMethod
    deliveryShipmentDate
    deliveryTrackingNumber
    friendlyName
    isPhysical
    linkcyId
    nameOnCard
    pinStatus
    scheme
    status
    dailyMaxSpend
    monthlyMaxSpend
    weeklyMaxSpend
    expiryDate
    truncatedPan
    online
    nfc
    trxReccuring
  }
`;
