export enum CARD_STATUS {
  CREATING = "CREATING",
  AWAITING_ACTIVATION = "AWAITING_ACTIVATION",
  ACTIVATING = "ACTIVATING",
  REACTIVATING = "REACTIVATING",
  ACTIVE = "ACTIVE",
  SUSPENDING = "SUSPENDING",
  SUSPENDED = "SUSPENDED",
  CLOSING = "CLOSING",
  CLOSED = "CLOSED",
  EXPIRED = "EXPIRED",
  FAILED = "FAILED",
}

export enum CARD_RULES {
  DAILY_MAX_SPEND = "DAILY_MAX_SPEND",
  MONTHLY_MAX_SPEND = "MONTHLY_MAX_SPEND",
  WEEKLY_MAX_SPEND = "WEEKLY_MAX_SPEND",
  DISABLE_CONTACTLESS = "DISABLE_CONTACTLESS",
  DISABLE_ONLINE = "DISABLE_ONLINE",
  DISABLE_RECURRING = "DISABLE_RECURRING",
  MCC_WHITELIST = "MCC_WHITELIST",
  MCC_BLACKLIST = "MCC_BLACKLIST",
  MERCHANT_WHITELIST = "MERCHANT_WHITELIST",
  MERCHANT_BLACKLIST = "MERCHANT_BLACKLIST",
  DAY_WHITELIST = "DAY_WHITELIST",
  DAY_BLACKLIST = "DAY_BLACKLIST",
}

export enum CARD_OPERATOR {
  END_USER = "END_USER",
  PARTNER = "PARTNER",
  LINKCY = "LINKCY",
}

export enum CHOISE_CARD {
  DETAILS = "DETAILS",
  PIN = "PIN",
}

export enum CARD_SCHEME {
  MASTERCARD = "MASTERCARD",
  VISA = "VISA",
}
